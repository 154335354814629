
import { computed, defineComponent, ref } from "vue";
import Modal from "@/components/atoms/Modal.vue";
import TextField from "@/components/atoms/TextField.vue";
import {
  MovementService,
  MovementEnum,
  GroupEnum,
  MovementEnumSpanish,
} from "@/services/shareholder_shares/MovementService";

import { useAuthStore } from "@/stores/auth";
import { AccountService } from "@/services/account/AccountService";
import { useRouter } from "vue-router";
import { shareMovementStore } from "@/stores/movements";
import { shareholderStore } from "@/stores/shareholder";
import { accountStore } from "@/stores/account";
import Swal from "sweetalert2";

declare type enumType = keyof typeof MovementEnum;
declare type enumGrup = keyof typeof GroupEnum;
declare type enumSpanish = keyof typeof MovementEnumSpanish;
export default defineComponent({
  components: { Modal, TextField },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:visible"],
  setup(props, { emit }) {
    const accountStoreStr = accountStore();
    const authStore = useAuthStore();

    const shareholderStr = shareholderStore();
    const movementsStore = shareMovementStore();
    const router = useRouter();
    const getMovementCod = (key: enumType) => MovementEnum[key];
    // const getMovementCodSpanish = (key: enumType) => MovementEnumSpanish[key];
    const shareholderId = parseInt(localStorage.getItem("user_id") || "0", 10);

    const movementService = new MovementService();

    const accountService = new AccountService();
    const loading = ref(false);
    const hasError = ref(false);
    const errorMessage = ref("");

    // Información basica

    const backCode = ref<number>(0);
    const backName = ref<string>("");
    const accountType = ref<string>("");
    const accountNumber = ref<string>("");
    const accountOwnerName = ref<string>("");
    const accountOwnerRut = ref<string>("");
    const accountEmail = ref<string>("");

    /**
     * Comprueba si el valor ingresado como parámetro es nulo o no definido.
     * @param value Valor a comprobar
     */
    function isNullOrEmpty(value?: string | number | null): boolean {
      return !value || value == null || value == "" || value == 0;
    }
    function isEmptyOrZero(value?: number): boolean {
      return !value || value == null || value == 0;
    }
    function isEmptyOrLarge(value?: string): boolean {
      return (
        !value ||
        value == null ||
        value == "" ||
        value.length == 0 ||
        value.length > 35
      );
    }
    function isRut(value?: string): boolean {
      return (
        !value ||
        value == null ||
        value == "" ||
        value.length == 0 ||
        value.length > 35
      );
    }

    // Indica si el formulario tiene algun error de dato o valor requerido
    const formHasError = computed(() => {
      const requiredFields = [
        backCode.value,
        backName.value,
        accountType.value,
        accountNumber.value,
        accountOwnerName.value,
        accountOwnerRut.value,
        accountEmail.value,
      ];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        backCodeFieldHasError.value,
        backNameFieldHasError.value,
        accountTypeFieldHasError.value,
        accountNumberFieldHasError.value,
        accountOwnerNameFieldHasError.value,
        accountOwnerRutFieldHasError.value,
        accountEmailFieldHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });

    // const dateFieldHasError = computed(() => {
    //   return isNullOrEmpty(date.value);
    // });

    const backCodeFieldHasError = computed(() => {
      return isEmptyOrZero(backCode.value);
    });

    const backNameFieldHasError = computed(() => {
      return isEmptyOrLarge(backName.value);
    });

    const accountTypeFieldHasError = computed(() => {
      return isEmptyOrLarge(accountType.value);
    });

    const accountNumberFieldHasError = computed(() => {
      return isEmptyOrLarge(accountNumber.value);
    });
    const accountOwnerNameFieldHasError = computed(() => {
      return isEmptyOrLarge(accountOwnerName.value);
    });

    const accountOwnerRutFieldHasError = computed(() => {
      return isRut(accountOwnerRut.value);
    });

    const accountEmailFieldHasError = computed(() => {
      return isEmptyOrLarge(accountEmail.value);
    });
    /**
     * Envia una solicitud al backend para registrar al nuevo accionista
     */
    async function registerNewAccount() {
      loading.value = true;

      try {
        const response = await accountService.registerNewAccount(
          backCode.value,
          backName.value,
          accountType.value,
          accountNumber.value,
          accountOwnerName.value,
          accountOwnerRut.value,
          accountEmail.value
        );
        if (response.success == true) {
          loading.value = false;
          emit("update:visible", false);

          if (authStore.user?.id) {
            accountStoreStr.query(authStore.user.id);
          }

          backCode.value = 0;
          backName.value = "";
          accountType.value = "";
          accountNumber.value = "";
          accountOwnerName.value = "";
          accountOwnerRut.value = "";
          accountEmail.value = "";
          Swal.fire("¡Listo!", "La cuenta se ha creado con éxito", "success");
        } else {
          Swal.fire(
            "¡Error!",
            "Lo sentimos, hubo un error, intente más tarde",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      loading,
      hasError,
      errorMessage,
      registerNewAccount,
      isNullOrEmpty,

      backCode,
      backName,
      accountType,
      accountNumber,
      accountOwnerName,
      accountOwnerRut,
      accountEmail,

      backCodeFieldHasError,
      backNameFieldHasError,
      accountTypeFieldHasError,
      accountNumberFieldHasError,
      accountOwnerNameFieldHasError,
      accountOwnerRutFieldHasError,
      accountEmailFieldHasError,

      formHasError,
    };
  },
});
