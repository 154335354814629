import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative w-14 h-6 p-0.5 rounded-full cursor-pointer transition-colors", { 'bg-gray-400': !_ctx.actived, 'bg-[#008b4e]': _ctx.actived }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick()))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute w-5 h-5 rounded-full bg-white transition-transform", { 'translate-x-0': !_ctx.actived, 'translate-x-8': _ctx.actived }])
    }, null, 2)
  ], 2))
}