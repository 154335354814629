export interface UserSession {
    createdAt: string
    id: number      // Identificador de la sesion
    userId: number  // Identificador del usuario
    token: string   // Token de la sesión (guardar esto)
}

export interface User {
    id: number
    isAdmin: boolean
    isShareholder: boolean
    investorNumberA: number | null
    investorNumberB: number | null
    totalSharesA: number
    totalSharesB: number
    
    // Información del usuario
    firstName: string
    lastName: string
    email: string
    phone: string
    rut: string
    address: string
        
    // Información de al empresa
    businessName: string | null
    businessAddress: string | null
    businessRut: string | null
        
    // Información bancaria
    bankName: string
    accountType: string
    accountNumber: string
    accountOwnerName: string
    accountOwnerRut: string
    accountEmail: string

    //Información personería
    writingDate: string | null
    notary: string | null
    repertoireNumber: string | null
}

export interface UserResume {
    id: number
    firstName: string
    lastName: string
    rut: string
    investorNumberA: number
    investorNumberB: number
    totalSharesA: number
    totalSharesB: number
    dividendBalanceClp: number 

    // Información de al empresa
    businessName: string | null
    businessAddress: string | null
    businessRut: string | null
}
export class ChangePasswordData {
    constructor(
      public user_id: number,
      public password: string,
      public old_password: string
    ) {}
  }

export interface UserBanner {
    fullName: string
    email: string
    phone: string
}
export const bankNames = [
    "Banco Bice",
    "BBVA",
    "Banco Consorcio",
    "Banco De Chile - Edwards Citi",
    "Banco Del Desarrollo",
    "Banco Estado",
    "Banco Falabella",
    "Banco Internacional",
    "Banco Itaú",
    "Banco Paris",
    "Banco Ripley",
    "Banco Santander - Banefe",
    "Banco Security",
    "Bci - Tbanc - Nova",
    "Coopeuch",
    "Corpbanca",
    "HSBC Bank",
    "Los Héroes",
    "Mercado Pago Emisora S.A.",
    "Scotiabank",
    "TAPP Caja Los Andes",
    "TENPO Prepago",
    "Transbank"
]

export const bankAccountTypes = [
    "Cuenta Corriente",
    "Cuenta Vista",
    "Cuenta de Ahorro"
]
