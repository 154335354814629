
import { defineComponent } from "vue";
import { useAuthStore } from "@/stores/auth";
import { formatRut } from "@/services/utils";

export default defineComponent({
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
      formatRut,
    };
  },
});
