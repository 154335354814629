import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17898ed3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex lg:flex-row flex-col gap-5 p-6" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text-[16px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex rounded-full border overflow-hidden",
          key: tab.name,
          onClick: ($event: any) => (_ctx.setTab(tab))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["cursor-pointer w-full lg:w-[155px] h-[40px] flex justify-center items-center unselected-tab", {
            'font-bold selected-tab': tab.name == _ctx.activeTab?.name,
          }])
          }, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(tab.name), 1)
          ], 2)
        ], 8, _hoisted_3))
      }), 128))
    ]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeTab?.view)))
  ]))
}