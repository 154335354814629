
import { Component, defineComponent, PropType, Ref, ref } from "vue";
import { AuthService } from "@/services/auth/AuthService";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

export interface Tab {
  name: string;
  view: Component;
}

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Array<Tab>>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const authService = new AuthService();
    const authStore = useAuthStore();
    const activeTab: Ref<Tab | null> = ref(props.tabs[0]);
    async function logout() {
      if (!authStore.token) return;
      const response = await authService.logout(authStore.token);
      if (response.success) {
        router.push({ name: "auth-login" });
        authStore.logout();
      }
    }
    function setTab(tab: Tab) {
      activeTab.value = tab;
    }

    return {
      activeTab,
      setTab,
      logout,
    };
  },
});
