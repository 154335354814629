import { User, UserResume } from "../auth/types";

export class Shareholder implements User{
    id!: number;
    isAdmin!: boolean;
    isShareholder!: boolean;
    investorNumberA!: number | null;
    investorNumberB!: number | null;
    totalSharesA!: number;
    totalSharesB!: number;
    firstName!: string;
    lastName!: string;
    email!: string;
    phone!: string;
    rut!: string;
    address!: string;
    dividendBalanceClp!: number;
    businessName!: string | null;
    businessAddress!: string | null;
    businessRut!: string | null;
    bankName!: string;
    accountType!: string;
    accountNumber!: string;
    accountOwnerName!: string;
    accountOwnerRut!: string;
    accountEmail!: string;
    writingDate!: string | null
    notary!: string | null
    repertoireNumber!: string | null

    get isBusiness(){
        if(this.businessName)
            return true
        return false
    }
    
    get identificationName(){
        if(this.isBusiness)
            return this.businessName
        return this.firstName + " " + this.lastName
    }

    get identificationRut(){
        if(this.isBusiness)
            return this.businessRut
        return this.rut
    }

    get identificationIconName(){
        if(this.isBusiness)
            return 'domain'
        return 'account'
    }
}

export class ShareholderResume implements UserResume{
    id!: number;
    firstName!: string;
    lastName!: string;
    rut!: string;
    investorNumberA!: number;
    investorNumberB!: number;
    totalSharesA!: number;
    totalSharesB!: number;
    dividendBalanceClp! : number;
    businessName!: string | null;
    businessAddress!: string | null;
    businessRut!: string | null;
    
    
    get isBusiness(){
        if(this.businessName)
            return true
        return false
    }

    get identificationName(){
        if(this.isBusiness)
            return this.businessName
        return this.firstName + " " + this.lastName
    }

    get identificationRut(){
        if(this.isBusiness)
            return this.businessRut
        return this.rut
    }

    get identificationIconName(){
        if(this.isBusiness)
            return 'domain'
        return 'account'
    }
}