
import ShareholderCard from "@/components/molecules/ShareholderCard.vue";
import { defineComponent, markRaw, computed } from "vue";
import TabTemplate from "@/components/templates/TabTemplate.vue";
import ShareholderIdentificationTab from "@/views/tabs/ShareholderIdentificationTab.vue";
import ShareholderNotificationSettingsTab from "@/views/tabs/ShareholderNotificationSettingsTab.vue";
import ShareholderAccountTab from "@/views/tabs/ShareholderAccountTab.vue";
import { accountStore } from "@/stores/account";

export default defineComponent({
  setup() {
    const accountStr = accountStore();
    const activeAccount = computed(() => accountStr.activeAccount);
    return {
      activeAccount,
      ShareholderIdentificationTab,
      ShareholderNotificationSettingsTab,
      ShareholderAccountTab,
      markRaw,
    };
  },
  components: { ShareholderCard, TabTemplate },
});
