
import { computed, watch, defineComponent, ref } from "vue";
import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import NewAccountModal from "@/components/organisms/NewAccountModal.vue";
import NewAccountViewModal from "@/components/organisms/NewAccountViewModal.vue";
import { bankNames, bankAccountTypes } from "@/services/auth/types";
import { useAuthStore } from "@/stores/auth";
import { AuthService } from "@/services/auth/AuthService";
import { reformatDate, reformatDateISO8601 } from "@/services/utils";
import { accountStore } from "@/stores/account";
import { shareMovementStore } from "@/stores/movements";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { NewAccountModal, NewAccountViewModal },

  setup() {
    const showNewAccountModal = ref(false);
    const router = useRouter();
    const movementsStore = shareMovementStore();
    const authStore = useAuthStore();
    const changed = ref<boolean>(false);
    const shareholder = computed(() => authStore.user);
    const accountStr = accountStore();

    const accountOpen = ref<number>(0);
    const visible = ref<boolean>(false);
    const visibleView = ref<boolean>(false);
    const nameAccount = ref<string>("");
    const nameAccountFieldHasError = computed(() => {
      return nameAccount.value.length == 0;
    });

    const activeCount = computed(() => accountStr.activeAccount);
    const accounts = computed(() => accountStr.account);

    watch(
      shareholder,
      (value, oldValue) => {
        if (oldValue != undefined) {
          changed.value = true;
        }
      },
      { deep: true }
    );

    const authService = new AuthService();

    async function setWritingDate(date: string) {
      if (shareholder.value == undefined) return;
      shareholder.value.writingDate = reformatDateISO8601(new Date(date));
    }

    async function submitUser() {
      if (shareholder.value == undefined) return;
      const response = await authService.changeInfo(shareholder.value);
      if (response.success) alert("Se ha cambiado exitosamente");
      else alert("No se ha podido cambiar");
    }

    const openModalNewAccount = () => {
      visible.value = true;
    };
    const submitNewAccount = () => {
      visible.value = false;
    };

    const openModalViewAccount = (id: number) => {
      visibleView.value = true;
      accountOpen.value = id;
      console.log("entra aca");
    };
    const closeEditAccount = () => {
      visibleView.value = false;
    };

    async function setAccount(id: number) {
      accountStr.setActiveAccount(id);
      router.push({ name: "MyStocks" });
    }

    return {
      showNewAccountModal,
      nameAccount,
      bankNames,
      bankAccountTypes,
      shareholder,
      changed,
      accounts,
      submitUser,
      reformatDate,
      setWritingDate,
      openModalNewAccount,
      visible,
      visibleView,
      nameAccountFieldHasError,
      submitNewAccount,
      openModalViewAccount,
      accountOpen,
      setAccount,
      activeCount,
    };
  },
});
