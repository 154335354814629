import { Account,AccountArray } from "@/services/account/types";
import { defineStore } from "pinia";
import { AccountService } from "@/services/account/AccountService";
import {  Ref, ref } from "vue";

export const accountStore = defineStore("account", {
  state: () => {
    const account: AccountArray = new AccountArray()
    const activeAccount: Ref<number> = ref(+(localStorage.getItem('activeAccount') ?? 0));
    const accountService = new AccountService();
    const promise: Promise<Response> = new Promise((res) => res);
    const accountSelected: Ref<Account|null>= ref(null)

    return {
      account,
      accountService,
      promise,
      activeAccount,
      accountSelected,
    };
  },

  actions: {
    async query(id: number) {
      await this.accountService
        .getAccountServices(id)
        .then(({ data }) => {
          this.account.data = data.data 
          if(this.account.data){
            if(this.account.data.length == 1 && this.account.data[0] && this.account.data[0].id){
              this.setActiveAccount(this.account.data[0].id);
            }
          }
        });
      
    },
    setActiveAccount(id:number){
      localStorage.setItem('activeAccount', `${id}`);
      this.activeAccount = id; 
      this.account.data?.forEach(a=>{
        if(a.id == id){
          this.accountSelected = a;
          
        }
      })
    }
  },

  getters: {
    getData(state) {
      return state.account;
    },
  },
});

export type AccountStore = ReturnType<typeof accountStore>;
