import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse } from "../utils";

export enum MovementEnum {
  "SALE" = 0,
  "BUY" = 1,
  "" = 2,
}
export enum MovementEnumSpanish {
  "Venta" = 0,
  "Compra" = 1,
  "" = 2,
}

export enum GroupEnum {
  "GRUPO A" = 0,
  "GRUPO B" = 1,
  "" = 2,
}
export class MovementService extends AuthenticatedService {
  async registerMovementsA(
    shareholderId: number,
    date: string,
    typeAction: string,
    codeAction: string,
    amount: number | undefined,
    activeAccount: number,

  ) {
    const data = {
      id: this.authStore.user?.id,
      user_id: shareholderId,
      date,
      request_type: typeAction == 'Compra' ? 'BUY':'SALE',
      code_action: codeAction,
      group_a_share_amount :amount,
      account_id:activeAccount

    };
    const url = `${getEnvConfig().apiURL}/requests/create`;

    console.log("pegar al endpoint: ", url, "con data: ", data);
    return getResponse(
      url,
      this.token,
      "POST",
      data,
      undefined,
    );
  }

  async registerMovementsB(
    shareholderId: number,
    date: string,
    typeAction: string,
    codeAction: string,
    amount: number | undefined,
    activeAccount: number,

  ) {
    const data = {
      id: this.authStore.user?.id,
      user_id: shareholderId,
      date,
      request_type: typeAction == 'Compra' ? 'BUY':'SALE',
      code_action: codeAction,
      group_b_share_amount :amount,
      account_id:activeAccount

    };
    const url = `${getEnvConfig().apiURL}/requests/create`;

    console.log("pegar al endpoint: ", url, "con data: ", data);
    return getResponse(
      url,
      this.token,
      "POST",
      data,
      undefined,
    );
  }
}

