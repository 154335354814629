
import { computed, defineComponent, ref } from "vue";
import Modal from "@/components/atoms/Modal.vue";
import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import {
  MovementService,
  MovementEnum,
  GroupEnum,
  MovementEnumSpanish,
} from "@/services/shareholder_shares/MovementService";
import { useRouter } from "vue-router";
import { shareMovementStore } from "@/stores/movements";
import { shareholderStore } from "@/stores/shareholder";
import { accountStore } from "@/stores/account";

import Swal from "sweetalert2";

declare type enumType = keyof typeof MovementEnum;
declare type enumGrup = keyof typeof GroupEnum;
declare type enumSpanish = keyof typeof MovementEnumSpanish;
export default defineComponent({
  components: { Modal, TextField, Selector },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:visible"],
  setup(props, { emit }) {
    const shareholderStr = shareholderStore();
    const movementsStore = shareMovementStore();

    const accountStr = accountStore();
    const activeAccount = computed(() => accountStr.activeAccount);

    const router = useRouter();
    const getMovementCod = (key: enumType) => MovementEnum[key];
    // const getMovementCodSpanish = (key: enumType) => MovementEnumSpanish[key];
    const shareholderId = parseInt(localStorage.getItem("user_id") || "0", 10);

    const movementService = new MovementService();
    const loading = ref(false);
    const hasError = ref(false);
    const errorMessage = ref("");

    // Información basica
    const date = ref(new Date().toString());
    const typeAction = ref<enumType>("");
    const group = ref<enumGrup>("");
    const amount = ref<number | undefined>(undefined);
    const sender_id = ref<number | undefined>(undefined);
    const typeActions = ref([MovementEnum[0], MovementEnum[1]]);
    const typeActionsSpanish = ref([
      MovementEnumSpanish[0],
      MovementEnumSpanish[1],
    ]);
    const groups = ref([GroupEnum[1]]);
    /**
     * Comprueba si el valor ingresado como parámetro es nulo o no definido.
     * @param value Valor a comprobar
     */
    function isNullOrEmpty(value?: string | number | null): boolean {
      return !value || value == null || value == "" || value == 0;
    }

    // Indica si el formulario tiene algun error de dato o valor requerido
    const formHasError = computed(() => {
      const requiredFields = [typeAction.value, amount.value];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        typeActionFieldHasError.value,
        amountFieldHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });

    // const dateFieldHasError = computed(() => {
    //   return isNullOrEmpty(date.value);
    // });

    const firstTimeTypeActionFieldHasError = ref(true);
    const firstTimeAmountFieldHasError = ref(true);

    const typeActionFieldHasError = computed(() => {
      if (typeAction.value == "") {
        return false;
      }

      return isNullOrEmpty(typeAction.value);
    });

    const amountFieldHasError = computed(() => {
      if (amount.value == undefined) {
        return false;
      }
      return isNullOrEmpty(amount.value);
    });

    /**
     * Envia una solicitud al backend para registrar al nuevo accionista
     */
    async function registerShareholder() {
      loading.value = true;

      try {
        if (group.value === "GRUPO A") {
          const response = await movementService.registerMovementsA(
            shareholderId,
            date.value,
            typeAction.value,
            group.value,
            amount.value,
            activeAccount.value
          );
          if (response.success == true) {
            loading.value = false;
            emit("update:visible", false);
            movementsStore.query();
            shareholderStr.query(shareholderId);
            group.value = "";
            typeAction.value = "";
            amount.value = undefined;
            Swal.fire(
              "¡Listo!",
              "Solicitud de Movimiento en GRUPO A realizada con éxito",
              "success"
            );
          } else {
            Swal.fire(
              "¡Error!",
              "Usuario no tiene suficientes acciones de ese grupo para solicitar esta venta",
              "error"
            );
          }
        } else if (group.value === "GRUPO B") {
          const response = await movementService.registerMovementsB(
            shareholderId,
            date.value,
            typeAction.value,
            group.value,
            amount.value,
            activeAccount.value
          );
          if (response.success == true) {
            loading.value = false;
            emit("update:visible", false);
            movementsStore.query();
            shareholderStr.query(shareholderId);
            group.value = "";
            typeAction.value = "";
            amount.value = undefined;
            Swal.fire(
              "¡Listo!",
              "Solicitud de Movimiento en GRUPO B realizada con éxito",
              "success"
            );
          } else {
            Swal.fire(
              "¡Error!",
              "Usuario no tiene suficientes acciones de ese grupo para solicitar esta venta",
              "error"
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      accountStr,
      loading,
      hasError,
      errorMessage,
      registerShareholder,
      isNullOrEmpty,
      date,
      typeAction,
      group,
      amount,
      activeAccount,
      sender_id,
      groups,
      typeActions,
      typeActionsSpanish,
      typeActionFieldHasError,
      amountFieldHasError,
      formHasError,
    };
  },
});
