
import { computed, defineComponent, ref } from "vue";
import TextField from "@/components/atoms/TextField.vue"; // Asegúrate de importar TextField correctamente
import Modal from "../atoms/Modal.vue";
import { ChangePasswordData } from "@/services/auth/types";
import { AuthService } from "@/services/auth/AuthService";
import { useAuthStore } from "@/stores/auth";
import router from "@/router";
import Swal from "sweetalert2";

export default defineComponent({
  components: {
    TextField, // Asegúrate de registrar TextField correctamente
    Modal,
  },
  props: {
    visible: {
      type: Boolean,
      defaul: false,
    },
  },
  setup(prop, { emit }) {
    const oldPassword = ref("");
    const newPassword = ref("");
    const authStore = useAuthStore();
    const shareholder = computed(() => authStore.user);
    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );

    async function submitForm() {
      // Emitir evento con los datos de la contraseña actual y nueva
      const authService = new AuthService();
      const changePasswordData = new ChangePasswordData(
        shareholder?.value?.id ?? 1,
        newPassword.value,
        oldPassword.value
      );
      let res = await authService.changePassword(changePasswordData);
      if (!res.success) {
        Swal.fire(
          "Error!",
          "Ha surgido un error, intentelo nuevamente",
          "error"
        );
        emit("update:visible", false);
        return false;
      }
      Swal.fire("Listo!", "Contraseña actualizada correctamente", "success");
      emit("update:visible", false);
    }

    function closeModal() {
      // Emitir evento para cerrar el modal
      emit("close");
    }

    return {
      oldPassword,
      newPassword,
      submitForm,
      closeModal,
    };
  },
});
