import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-1/6 items-end" }
const _hoisted_2 = { class: "flex flex-col w-full gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createVNode(_component_Datepicker, {
        cancelText: 'Cancelar',
        selectText: 'Seleccionar',
        "year-picker": "",
        modelValue: _ctx.year,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.year) = $event)),
        "enable-time-picker": false
      }, null, 8, ["modelValue"])
    ])
  ]))
}