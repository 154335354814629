import { User,ChangePasswordData, UserSession } from "./types";
import { Response } from "../types";
import { getEnvConfig } from "@/configs/UrlConfig";
import { getResponse,keysToSnake, } from "../utils";
import { useAuthStore } from "@/stores/auth";
import { Shareholder } from "../shareholder/types";
import { AuthenticatedService } from "./AuthenticatedService";

export class AuthService extends AuthenticatedService{
    async login(email: string, password: string): Promise<Response<UserSession>>{
        const url = `${getEnvConfig().apiURL}/auth/login`

        const formData = new FormData()
        formData.append('username', email)
        formData.append('password', password)

        return getResponse(url, undefined, 'POST', undefined, formData)
    }
    async forgotPassword(email: string): Promise<Response<UserSession>>{
        const url = `${getEnvConfig().apiURL}/auth/forgot-password?type=2`


        return getResponse(url, undefined, 'POST', {email:email})
    }
    async forgotPasswordReturn(data: object): Promise<Response<UserSession>>{
        const url = `${getEnvConfig().apiURL}/auth/reset-password`

        return getResponse(url, undefined, 'POST', data)
    }

    async getPerfil(token: string): Promise<Response<Shareholder>> {
        const url = `${getEnvConfig().apiURL}/auth/me`

        return getResponse(url, token, "GET", undefined, undefined, Shareholder)
    }

    async logout(token: string): Promise<Response<any>>{
        const url = `${getEnvConfig().apiURL}/auth/logout`

        return getResponse(url, token)
    }

    /**
     * Valida que la sesion del usuario es valida.
     * @returns Retorna True si la sesión es valida y False en caso contrario
     */
    async hasValidUserSession(){
        const authStore = useAuthStore()
        if(authStore.token){
            const response = await this.getPerfil(authStore.token)
            if(response.success){
                authStore.setUser(response.data)
                return true
            }
            else{
                authStore.logout()
                return false
            }
        }
        return false
    }
    async changePassword(
        Password: ChangePasswordData
      ): Promise<Response<ChangePasswordData>> {
        const url = `${getEnvConfig().apiURL}/auth/change-password`;
        return getResponse(
          url,
          this.token,
          "POST",
          Password,
          undefined
        );
      }
    async changeInfo(user: User): Promise<Response<null>>{
        const url = `${getEnvConfig().apiURL}/auth/change-personal-info`

        const data = {
            "email": user.email,
            "phone": user.phone,
            "address": user.address,
            "bank_name": user.bankName,
            "account_type": user.accountType,
            "account_number": user.accountNumber,
            "account_owner_name": user.accountOwnerName,
            "account_owner_rut": user.accountOwnerRut,
            "account_email": user.accountEmail,
            "business_address": user.businessAddress
        }

        return getResponse(url, this.token, "PATCH", data)
    }
}