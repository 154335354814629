
import { AuthService } from "@/services/auth/AuthService";
import { useAuthStore } from "@/stores/auth";
import { defineComponent, ref, watchEffect, onMounted } from "vue";

import NewMovementModal from "@/components/organisms/NewMovementModal.vue";
import NotificationMenu from "./NotificationMenu.vue";
import ContactMenu from "./ContactMenu.vue";
import AccountMenu from "./AccountMenu.vue";
import AccountSelectMenu from "./AccountSelectMenu.vue";

interface Route {
  name: string;
  label: string;
  iconName?: string;
  param?: Record<string, string | number>;
}

export default defineComponent({
  setup() {
    const showNewMovementModal = ref(false);
    const authStore = useAuthStore();
    const authService = new AuthService();

    const user = ref();

    watchEffect(() => {
      const response = authStore.user;
      user.value = response;
    });

    const routes: Route[] = [
      { name: "MyStocks", label: "", iconName: "chart-box" },
      { name: "MyPerfil", label: "", iconName: "account" },
    ];

    return {
      showNewMovementModal,
      routes,
      user,
    };
  },
  components: {
    NotificationMenu,
    ContactMenu,
    AccountMenu,
    NewMovementModal,
    AccountSelectMenu,
  },
});
