
import { defineComponent, ref, watch } from "vue";

import Datepicker from "@vuepic/vue-datepicker";

export default defineComponent({
  components: {
    Datepicker,
  },
  emits: ["submitFilterData"],
  props: {
    willRenderByType: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const grupoBSelected = ref<boolean>(true);
    const period = ref<string[]>();
    const year = ref<number>(new Date().getFullYear());
    watch(
      year,
      async (newValue, oldValue) => {
        if (newValue !== oldValue && newValue) {
          period.value = getYearStartAndEndDates(year.value);

          emit(
            "submitFilterData",
            false,
            grupoBSelected.value,
            period.value,
            year.value == new Date().getFullYear()
          );
        }
      },
      { immediate: true }
    );
    function getYearStartAndEndDates(
      year: number | undefined
    ): string[] | undefined {
      if (year == undefined) return undefined;
      const startDate = dateToNaiveISOString(new Date(year, 0, 1));
      const endDate = dateToNaiveISOString(new Date(year + 1, 0, 1));
      return [startDate, endDate];
    }
    function dateToNaiveISOString(dateObj: Date) {
      let isoStr = dateObj.toISOString();

      isoStr = isoStr.replace("T", " ");
      isoStr = isoStr.substring(0, isoStr.length - 5);

      return isoStr;
    }

    return {
      grupoBSelected,
      year,
    };
  },
});
