import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bad22a56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid grid-cols-8 box-shadow-profile px-0 lg:px-20 border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShareholderCard = _resolveComponent("ShareholderCard")!
  const _component_TabTemplate = _resolveComponent("TabTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ShareholderCard, { class: "col-span-8 border-b-[#9BB441] border-b-[20px] px-5" }),
    (_ctx.activeAccount != 0)
      ? (_openBlock(), _createBlock(_component_TabTemplate, {
          key: 0,
          class: "col-span-8",
          tabs: [
        {
          name: 'Cuentas ',
          view: _ctx.markRaw(_ctx.ShareholderAccountTab),
        },
        { name: 'Perfil', view: _ctx.markRaw(_ctx.ShareholderIdentificationTab) },
        {
          name: 'Notificaciones',
          view: _ctx.markRaw(_ctx.ShareholderNotificationSettingsTab),
        },
      ]
        }, null, 8, ["tabs"]))
      : _createCommentVNode("", true),
    (_ctx.activeAccount == 0)
      ? (_openBlock(), _createBlock(_component_TabTemplate, {
          key: 1,
          class: "col-span-8",
          tabs: [
        {
          name: 'Cuentas ',
          view: _ctx.markRaw(_ctx.ShareholderAccountTab),
        },
        { name: 'Perfil', view: _ctx.markRaw(_ctx.ShareholderIdentificationTab) },
      ]
        }, null, 8, ["tabs"]))
      : _createCommentVNode("", true)
  ]))
}