import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "shadow-lg border rounded-lg flex flex-col bg-white h-full" }
const _hoisted_2 = { class: "flex-none flex gap-2" }
const _hoisted_3 = {
  key: 0,
  class: "px-2 pt-2 font-bold flex-1 truncate text-left"
}
const _hoisted_4 = { class: "overflow-auto pb-4" }
const _hoisted_5 = { class: "p-2 flex-none flex gap-2" }
const _hoisted_6 = {
  key: 0,
  class: "px-2 pt-2 flex-1 text-sm font-light truncate text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "header")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "footer", {}, () => [
        (_ctx.subtitle)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.subtitle), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}