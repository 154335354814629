
import { defineComponent, ref, onMounted, computed } from "vue";
import { ShareholderService } from "@/services/shareholder/ShareholderService";
import { shareholderStore } from "@/stores/shareholder";
import { toBadgeFormat } from "@/services/utils";
import { accountStore } from "@/stores/account";

export default defineComponent({
  props: {
    shareholders: {
      type: Number || null,
    },
    shares: {
      type: Number || null,
    },
  },
  setup(props, { emit }) {
    const shareholderStr = shareholderStore();
    const shareholder = computed(() => shareholderStr.shareholder);
    const dividendClp = ref();

    const accountStr = accountStore();
    const activeAccount = computed(() => accountStr.activeAccount);

    onMounted(async () => {
      try {
        const shareholderService = new ShareholderService();
        const response = await shareholderService.getShareholderDetail(
          shareholder.value.id
        );

        if (response.success) {
          dividendClp.value = response.data.dividendBalanceClp.toString();
        }
      } catch (error) {
        console.error(error);
      }
    });

    return {
      toBadgeFormat,
      dividendClp,
      activeAccount,
      accountStr,
    };
  },
});
