
import { computed, watch, defineComponent, ref } from "vue";
import TextField from "@/components/atoms/TextField.vue";
import Selector from "@/components/atoms/Selector.vue";
import NewAccountModal from "@/components/organisms/NewAccountModal.vue";
import { bankNames, bankAccountTypes } from "@/services/auth/types";
import { useAuthStore } from "@/stores/auth";
import { AuthService } from "@/services/auth/AuthService";
import { AccountService } from "@/services/account/AccountService";
import ChangePasswordForm from "@/components/molecules/ChangePasswordForm.vue";
import {
  reformatDate,
  reformatDateISO8601,
  validateRut,
} from "@/services/utils";
import { accountStore } from "@/stores/account";
import Swal from "sweetalert2";

export default defineComponent({
  components: { TextField, Selector, NewAccountModal, ChangePasswordForm },

  setup() {
    const showChangePasswordModal = ref<boolean>(false);
    const showNewAccountModal = ref(false);
    const authStore = useAuthStore();
    const changed = ref<boolean>(false);
    const accountService = new AccountService();
    const shareholder = computed(() => authStore.user);

    const visible = ref<boolean>(false);
    const nameAccount = ref<string>("");
    const nameAccountFieldHasError = computed(() => {
      return nameAccount.value.length == 0;
    });

    const accountStr = accountStore();
    const activeAccount = computed(() => accountStr.activeAccount);

    watch(
      shareholder,
      (value, oldValue) => {
        if (oldValue != undefined) {
          changed.value = true;
        }
      },
      { deep: true }
    );
    watch(
      accountStr,
      (value, oldValue) => {
        if (oldValue != undefined) {
          changed.value = true;
        }
      },
      { deep: true }
    );

    const authService = new AuthService();

    async function setWritingDate(date: string) {
      if (accountStr.accountSelected == undefined) return;
      accountStr.accountSelected.writingDate = reformatDateISO8601(
        new Date(date)
      );
    }

    function isNullOrEmpty(value?: string | number | null): boolean {
      return !value || value == null || value == "" || value == 0;
    }
    const bankNameFieldHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      if (accountStr.accountSelected.bankName == "") {
        return true;
      }
      return isNullOrEmpty(accountStr.accountSelected.bankName);
    });
    const accountTypeFieldHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      if (accountStr.accountSelected.accountType == "") {
        return true;
      }
      return isNullOrEmpty(accountStr.accountSelected.accountType);
    });
    const accountNumberFieldHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      if (accountStr.accountSelected.accountNumber == "") {
        return true;
      }
      return isNullOrEmpty(accountStr.accountSelected.accountNumber);
    });
    const accountOwnerNameFieldHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      if (accountStr.accountSelected.accountOwnerName == "") {
        return true;
      }
      return isNullOrEmpty(accountStr.accountSelected.accountOwnerName);
    });
    const accountOwnerRutFieldHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      if (accountStr.accountSelected.accountOwnerRut == "") {
        return true;
      }
      return isNullOrEmpty(accountStr.accountSelected.accountOwnerRut);
    });
    const accountEmailFieldHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      if (accountStr.accountSelected.accountEmail == "") {
        return true;
      }
      return isNullOrEmpty(accountStr.accountSelected.accountEmail);
    });

    const rutFieldHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      return (
        isNullOrEmpty(accountStr.accountSelected.businessRut) ||
        !validateRut(accountStr.accountSelected.businessRut ?? "")
      );
    });

    const formHasError = computed(() => {
      if (!accountStr.accountSelected) {
        return false;
      }
      const requiredFields = [
        accountStr.accountSelected.bankName,
        accountStr.accountSelected.accountType,
        accountStr.accountSelected.accountNumber,
        accountStr.accountSelected.accountOwnerName,
        accountStr.accountSelected.accountOwnerRut,
        accountStr.accountSelected.accountEmail,
      ];

      if (requiredFields.some((value) => isNullOrEmpty(value))) {
        return true;
      }

      // Listao de posibles errores del formulario de registro
      const formErrors = [
        bankNameFieldHasError.value,
        accountTypeFieldHasError.value,
        accountNumberFieldHasError.value,
        accountOwnerNameFieldHasError.value,
        accountOwnerRutFieldHasError.value,
        accountEmailFieldHasError.value,
      ];
      if (formErrors.some((hasError) => hasError == true)) {
        return true;
      }

      return false;
    });
    async function submitUser() {
      if (shareholder.value == undefined) return;
      const response = await authService.changeInfo(shareholder.value);
      if (response.success) {
        if (authStore.token) {
          const responseperfil = await authService.getPerfil(authStore.token);
          authStore.setUser(responseperfil.data);
        }
        if (accountStr.accountSelected) {
          const responseAccount = await accountService.changeInfo(
            accountStr.accountSelected
          );
          if (responseAccount.success) {
            Swal.fire(
              "¡Listo!",
              "Los datos de la cuenta se han modificado con éxito.",
              "success"
            );
          } else {
            Swal.fire(
              "Error!",
              "Los datos de la cuenta no han sido modificados. Inténtelo nuevamente.",
              "error"
            );
          }
        } else {
          Swal.fire(
            "¡Listo!",
            "Los datos del usuario se han modificado con éxito.",
            "success"
          );
        }
      } else {
        Swal.fire(
          "Error!",
          "Los datos del usuario no han sido modificados. Inténtelo nuevamente.",
          "error"
        );
      }
    }

    const openModalNewAccount = () => {
      visible.value = true;
    };
    const submitNewAccount = () => {
      console.log("");
      visible.value = false;
    };
    const registerNewAccount = () => {
      console.log("");
    };
    function openChangePasswordModal() {
      showChangePasswordModal.value = true;
    }

    return {
      showNewAccountModal,
      nameAccount,
      bankNames,
      bankAccountTypes,
      shareholder,
      accountStr,
      changed,
      activeAccount,
      submitUser,
      reformatDate,
      setWritingDate,
      openModalNewAccount,
      openChangePasswordModal,
      showChangePasswordModal,
      visible,
      nameAccountFieldHasError,
      registerNewAccount,
      submitNewAccount,
      bankNameFieldHasError,
      accountTypeFieldHasError,
      accountNumberFieldHasError,
      accountOwnerNameFieldHasError,
      accountOwnerRutFieldHasError,
      accountEmailFieldHasError,
      rutFieldHasError,
      formHasError,
    };
  },
});
