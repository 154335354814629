
import { User } from "@/services/auth/types";
import { computed, defineComponent, ref, Ref, markRaw } from "vue";
import { useRouter } from "vue-router";
import ShareholderCard from "@/components/molecules/ShareholderCard.vue";
import { shareholderStore } from "@/stores/shareholder";
import ChartSection from "@/components/organisms/ChartSection.vue";
import ActionsChartSection from "@/components/organisms/ActionsChartSection.vue";

export default defineComponent({
  components: { ChartSection, ActionsChartSection },

  setup() {
    const shareholderStr = shareholderStore();
    const router = useRouter();
    const shareholder: Ref<User | null> = ref(null);

    const shareholderId = computed(() =>
      Number(router.currentRoute.value.params.id)
    );

    shareholderStr.query(shareholderId.value);

    return {
      shareholder,
      ShareholderCard,
      markRaw,
    };
  },
});
