
import { NotificationServices } from "@/services/notifications/NotificationServices";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Notification } from "@/services/notifications/types";
import { reformatDate } from "@/services/utils";
import { accountStore } from "@/stores/account";
import { computed, watch } from "vue";

export default defineComponent({
  setup() {
    const notificationServices = new NotificationServices();
    const notifications = ref<Notification[]>([]);
    const unreadNotifications = computed(() =>
      notifications.value.filter((notification) => !notification.isRead)
    );
    const readedNotifications = computed(() =>
      notifications.value.filter((notification) => notification.isRead)
    );
    const notificationIconName = computed(() =>
      unreadNotifications.value.length > 0 ? "bell-badge" : "bell"
    );

    const menuIsVisible = ref(false);
    const openingMenu = ref(false);

    const accountStr = accountStore();
    const activeAccount = computed(() => accountStr.activeAccount);

    function showMenu() {
      menuIsVisible.value = true;
    }

    function closeMenu() {
      menuIsVisible.value = false;
    }

    onMounted(() => {
      addEventListener("keyup", onPressEsc);
    });

    onUnmounted(() => {
      removeEventListener("keyup", onPressEsc);
    });

    function onPressEsc(event: KeyboardEvent) {
      if (event.key == "Escape") {
        closeMenu();
      }
    }

    function clickOutside() {
      if (!openingMenu.value) {
        closeMenu();
      }
    }

    async function sendReadNotification(notificationId: number) {
      const response = await notificationServices.readNotification(
        notificationId
      );
      if (!response.success) {
        console.error("No se ha podido establecer como leida la notificaion");
        return;
      }
      fetchNotifications();
    }

    async function sendReadAllNotifications() {
      const response = await notificationServices.readAllNotifications(
        activeAccount.value
      );
      if (!response.success) {
        console.error(
          "No se ha podido establecer como leidas todas las notificaciones"
        );
        return;
      }
      fetchNotifications();
    }

    async function fetchNotifications() {
      const response = await notificationServices.getNotifications(
        activeAccount.value
      );

      if (!response.success) {
        console.error("No se ha podido obtener la lista de notificaciones");
        return;
      }

      notifications.value = response.data;
    }

    fetchNotifications();

    watch(activeAccount, () => {
      fetchNotifications();
    });

    return {
      notifications,
      showMenu,
      closeMenu,
      menuIsVisible,
      openingMenu,
      clickOutside,
      reformatDate,
      readedNotifications,
      unreadNotifications,
      notificationIconName,
      sendReadAllNotifications,
      sendReadNotification,
    };
  },

  watch: {
    menuIsVisible(value: boolean) {
      if (!value) {
        this.$emit("onClose");
      } else {
        this.openingMenu = true;
        setTimeout(() => {
          this.openingMenu = false;
        }, 500);
      }
    },
  },
});
