import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "items-center" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-red-500 ml-auto flex place-items-center"
}
const _hoisted_4 = ["value", "placeholder", "autocomplete", "readonly"]
const _hoisted_5 = ["value", "type", "id", "placeholder", "autocomplete", "disabled", "readonly"]
const _hoisted_6 = ["v-model", "type", "id", "placeholder", "autocomplete", "disabled", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["flex text-sm font-semibold pb-1", {
        'text-red-500': _ctx.hasError,
        'text-gray-700': !_ctx.hasError,
      }]),
          for: _ctx.name
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.hideDetails && _ctx.hasError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Popper, {
                  arrow: "",
                  class: "font-normal",
                  placement: "auto",
                  hover: "",
                  content: _ctx.errorMessage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_mdicon, {
                      name: "error",
                      size: "16"
                    })
                  ]),
                  _: 1
                }, 8, ["content"])
              ]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.iconName)
      ? (_openBlock(), _createBlock(_component_mdicon, {
          key: 1,
          class: "absolute ml-2 my-2",
          size: "20",
          name: _ctx.iconName
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (_ctx.type == 'textarea')
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 2,
          class: _normalizeClass(["appearance-none border rounded w-full py-2 px-2 leading-tight focus:outline-none hover:border-gray-400", {
        'text-gray-700 border-gray-300 focus:border-black': !_ctx.hasError,
        'text-red-700 border-red-300 focus:border-red-400': _ctx.hasError,
        'text-gray-400 border-gray-300 focus:border-gray-400': _ctx.readonly,
      }]),
          value: _ctx.model as string,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args))),
          placeholder: _ctx.placeholder,
          autocomplete: _ctx.autocomplete,
          rows: "4",
          readonly: _ctx.readonly
        }, null, 42, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.type != 'textarea' && _ctx.type != 'file')
      ? (_openBlock(), _createElementBlock("input", {
          key: 3,
          class: _normalizeClass([{
        'p-1 text-sm': _ctx.compact,
        'p-2': !_ctx.compact,
        'pl-8': _ctx.iconName,
        'border-r border-y rounded rounded-l-none': _ctx.isAcopled,
        'border rounded': !_ctx.isAcopled,
        'text-gray-700 border-gray-300 focus:border-black': !_ctx.hasError,
        'text-red-700 border-red-300 focus:border-red-400': _ctx.hasError,
        'text-gray-400 border-gray-300 focus:border-gray-300': _ctx.readonly,
      }, "appearance-none w-full leading-tight focus:outline-none disabled:border-gray-200 disabled:text-gray-200 placeholder:text-sm px-4"]),
          value: _ctx.model,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args))),
          type: _ctx.type,
          id: _ctx.name,
          placeholder: _ctx.placeholder,
          autocomplete: _ctx.autocomplete,
          disabled: _ctx.disabled,
          readonly: _ctx.readonly
        }, null, 42, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.type == 'file')
      ? (_openBlock(), _createElementBlock("input", {
          key: 4,
          class: _normalizeClass([{
        'p-1 text-sm': _ctx.compact,
        'p-2': !_ctx.compact,
        'pl-8': _ctx.iconName,
        'border-r border-y rounded rounded-l-none': _ctx.isAcopled,
        'border rounded': !_ctx.isAcopled,
        'text-gray-700 border-gray-300 focus:border-black': !_ctx.hasError,
        'text-red-700 border-red-300 focus:border-red-400': _ctx.hasError,
      }, "appearance-none w-full leading-tight focus:outline-none disabled:border-gray-200 disabled:text-gray-200"]),
          "v-model": _ctx.modelFile,
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args))),
          type: _ctx.type,
          id: _ctx.name,
          placeholder: _ctx.placeholder,
          autocomplete: _ctx.autocomplete,
          disabled: _ctx.disabled,
          readonly: _ctx.readonly
        }, null, 42, _hoisted_6))
      : _createCommentVNode("", true),
    (!_ctx.hideDetails)
      ? (_openBlock(), _createElementBlock("p", {
          key: 5,
          class: "text-red-500 text-xs italic min-h-[16px]",
          style: _normalizeStyle(_ctx.errorMessageStyle)
        }, _toDisplayString(_ctx.errorMessage), 5))
      : _createCommentVNode("", true)
  ]))
}