import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "bg-white max-w-[600px] w-80 lg:w-96 border rounded shadow-lg absolute right-[-100px] lg:right-0 top-8 max-h-96 overflow-y-scroll flex flex-col gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_BannerContac = _resolveComponent("BannerContac")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        class: "text-sm flex flex-row cursor-pointer text-white hover:text-light-green items-center gap-2",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMenu()))
      }, [
        _createVNode(_component_mdicon, { name: "headphones" })
      ])
    ]),
    (_ctx.menuIsVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BannerContac)
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.clickOutside]
  ])
}