import { ref } from 'vue';
import { defineStore, storeToRefs } from "pinia";

import { DividendService } from '@/services/dividends/dividendService';
import { DividendStatusType, PaymentStatus } from '@/services/dividends/types';
import { accountStore } from '@/stores/account';

export const useDividendsStore = defineStore('dividendsStore', () => {
    const dividendService = new DividendService();
    const accountStr = accountStore();              // not exportable!

    const {activeAccount} = storeToRefs(accountStr);

    const fetchResponse = ref<any>(null);
    const dividends  =ref<PaymentStatus[]>();

    const pagination = ref<any>({
        page:1,
        per_page:undefined,
        total_pages:undefined,
        total_record:undefined,
    })

    const fetchDividends = async () => {
        fetchResponse.value = await dividendService.getShareholderDividends(activeAccount.value, pagination.value.page);

        if(fetchResponse.value.success){
            if(fetchResponse.value.data) {
                dividends.value = fetchResponse.value.data?.data;
                pagination.value = {
                    page: fetchResponse.value.data.page,
                    per_page: fetchResponse.value.data.perPage,
                    total_pages: fetchResponse.value.data.totalPages,
                    total_record: fetchResponse.value.data.totalRecord,
                }
            }
        }
    }

    const getPrettyPrintStatus = (status: DividendStatusType) => {
        switch (status) {
          case DividendStatusType.PAID:
            return "Pagado";
          case DividendStatusType.PENDING:
            return "Pendiente";
          case DividendStatusType.REJECTED:
            return "Rechazado";
          default:
            break;
        }
        return status;
      }

    return {
        fetchResponse,
        pagination,
        dividends,
        activeAccount,

        fetchDividends,
        getPrettyPrintStatus,
    } 
})

export default useDividendsStore;