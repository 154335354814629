
import { NotificationServices } from "@/services/notifications/NotificationServices";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Notification } from "@/services/notifications/types";
import { reformatDate } from "@/services/utils";
import { computed } from "@vue/reactivity";
import BannerContac from "./BannerContac.vue";

export default defineComponent({
  setup() {
    const menuIsVisible = ref(false);
    const openingMenu = ref(false);
    function showMenu() {
      menuIsVisible.value = true;
    }
    function closeMenu() {
      menuIsVisible.value = false;
    }
    function clickOutside() {
      if (!openingMenu.value) {
        closeMenu();
      }
    }
    return {
      clickOutside,
      showMenu,
      menuIsVisible,
    };
  },
  components: { BannerContac },
});
