
import SwitchablePropertyCard from "@/components/molecules/SwitchablePropertyCard.vue";
import { defineComponent, ref } from "vue";
import {
  NotificationSettings,
  NotificationSettingsEdit,
  NotificationType,
} from "@/services/notifications/types";
import { NotificationServices } from "@/services/notifications/NotificationServices";
import { computed } from "@vue/reactivity";
import { accountStore } from "@/stores/account";
import Swal from "sweetalert2";

export default defineComponent({
  setup() {
    const notificationServices = new NotificationServices();
    const notificationSettings = ref<NotificationSettings | undefined>(
      undefined
    );

    const accountStr = accountStore();
    const activeAccount = computed(() => accountStr.activeAccount);

    function addNotificationBlock(...notificationsTypes: NotificationType[]) {
      if (!notificationSettings.value) return;
      notificationSettings.value.blockedNotifications.push(
        ...notificationsTypes
      );
    }

    function removeNotificationBlock(
      ...notificationsTypes: NotificationType[]
    ) {
      if (!notificationSettings.value) return;
      notificationSettings.value.blockedNotifications =
        notificationSettings.value.blockedNotifications.filter(
          (obj) => !notificationsTypes.includes(obj)
        );
    }

    function isBlocked(...notificationsTypes: NotificationType[]) {
      if (!notificationSettings.value) return false;
      return notificationsTypes.every((obj) =>
        notificationSettings.value?.blockedNotifications.includes(obj)
      );
    }

    const reportOnRequestStatusChange = computed({
      get() {
        const isNotificationBlocked = isBlocked(
          "USER_REQUEST_APPROVED",
          "USER_REQUEST_REJECTED"
        );
        return !isNotificationBlocked;
      },
      set(newValue) {
        if (newValue == false)
          addNotificationBlock(
            "USER_REQUEST_APPROVED",
            "USER_REQUEST_REJECTED"
          );
        else
          removeNotificationBlock(
            "USER_REQUEST_APPROVED",
            "USER_REQUEST_REJECTED"
          );
      },
    });

    const reportOnSharesMovement = computed({
      get() {
        const isNotificationBlocked = isBlocked(
          "USER_MOVEMENT_CANCELLED",
          "USER_MOVEMENT_CREATED"
        );
        return !isNotificationBlocked;
      },
      set(newValue) {
        if (newValue == false)
          addNotificationBlock(
            "USER_MOVEMENT_CANCELLED",
            "USER_MOVEMENT_CREATED"
          );
        else
          removeNotificationBlock(
            "USER_MOVEMENT_CANCELLED",
            "USER_MOVEMENT_CREATED"
          );
      },
    });

    const reportOnDividendPayment = computed({
      get() {
        const isNotificationBlocked = isBlocked("ADMIN_DIVIDEND_CREATED");
        return !isNotificationBlocked;
      },
      set(newValue) {
        if (newValue == false) addNotificationBlock("ADMIN_DIVIDEND_CREATED");
        else removeNotificationBlock("ADMIN_DIVIDEND_CREATED");
      },
    });

    async function fetchNotificationSettings() {
      const response = await notificationServices.getSettings(
        activeAccount.value
      );
      if (!response.success) {
        console.error(
          "Ha ocurrido un error intentando obtener las preferencias de notificaciones"
        );
        return;
      }
      notificationSettings.value = response.data;
    }

    async function submitConfig() {
      const newSettings: NotificationSettingsEdit = {
        receiveMails: notificationSettings.value?.receiveMails,
        blockedNotifications: notificationSettings.value?.blockedNotifications,
      };
      const response = await notificationServices.editSettings(
        activeAccount.value,
        newSettings
      );

      if (!response.success) {
        console.error("No se pudieron guardar los cambios");
        return;
      }

      Swal.fire(
        "¡Listo!",
        "La información se ha modificado de forma éxitosa",
        "success"
      );
    }

    fetchNotificationSettings();

    return {
      notificationSettings,
      reportOnRequestStatusChange,
      reportOnSharesMovement,
      reportOnDividendPayment,
      submitConfig,
    };
  },
  components: { SwitchablePropertyCard },
});
