
import { defineComponent, ref } from "vue";
import axios from "axios";
import { getEnvConfig } from "@/configs/UrlConfig";

interface Usuario {
  fullName: string;
  email: string;
  phone: string;
}

// Importar axios para hacer las solicitudes HTTP

export default defineComponent({
  setup() {
    const usuarios = ref<Usuario[]>([]);

    axios
      .get(`${getEnvConfig().apiURL}/auth/admin-contact-info`)
      .then((response) => {
        usuarios.value = response.data as Usuario[];
      })
      .catch((error) => {
        console.log(error);
      });
    return {
      usuarios,
    };
  },
});
