import { ShareholderMovement } from "@/services/movements/types";
import { defineStore } from "pinia";
import { MovementService } from "@/services/movements/MovementService";
import {  Ref, ref } from "vue";
import { accountStore } from "@/stores/account";

export const shareMovementStore = defineStore("movements", {
  state: () => {
    const movements: ShareholderMovement[] = [];
    const movementService = new MovementService();
    const promise: Promise<Response> = new Promise((res) => res);
    const currentPage: Ref<number> = ref(1);
    const totalPages: Ref<number> = ref(0);
    const perPage: Ref<number> = ref(0);
    const accountStr = accountStore();  
    const filter: object = {
        start_date: '',
        end_date: '',
    };

    return {
      movements,
      accountStr,
      movementService,
      promise,
      currentPage,
      totalPages,
      perPage,
      filter,
    };
  },

  actions: {
    async query(page = 0) {
    //console.log('la cuenta es : ',this.accountStr.activeAccount)
      await this.movementService
        .getMovements(page, this.accountStr.activeAccount, this.filter)
        .then((response) => {
          this.movements = response.data;
          this.currentPage = response.page;
          this.totalPages = response.total_pages;
          this.perPage = response.per_page;
        });
    },
  },

  getters: {
    getData(state) {
      return state.movements;
    },
    getFilter(state){
        return state.filter;
    }
  },
});

export type AuthStore = ReturnType<typeof shareMovementStore>;
