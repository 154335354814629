
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores/auth";
import { AccountService } from "@/services/account/AccountService";
import { accountStore } from "@/stores/account";
import { shareMovementStore } from "@/stores/movements";

export default defineComponent({
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const router = useRouter();

    const movementsStore = shareMovementStore();
    const authStore = useAuthStore();
    const accountService = new AccountService();
    const accountStr = accountStore();
    const activeAccount = computed(() => accountStr.activeAccount);

    const menuIsVisible = ref(false);
    const openingMenu = ref(false);

    const accounts = computed(() => accountStr.account);

    onMounted(() => {
      getAccountServices();
    });

    function showMenu() {
      menuIsVisible.value = true;
    }
    function closeMenu() {
      menuIsVisible.value = false;
    }
    function clickOutside() {
      if (!openingMenu.value) {
        closeMenu();
      }
    }

    async function getAccountServices() {
      if (authStore.user?.id) {
        accountStr.query(authStore.user.id);
      }
    }
    async function setAccount(id: number) {
      accountStr.setActiveAccount(id);
      movementsStore.query();
      router.push({ name: "MyStocks" });
    }

    return {
      accounts,
      clickOutside,
      showMenu,
      menuIsVisible,
      setAccount,
      activeAccount,
    };
  },
});
