import { getEnvConfig } from "@/configs/UrlConfig";
import { getResponse } from "../utils";
import { Response } from "../types";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { Shareholder, ShareholderResume } from "./types";

export class ShareholderService extends AuthenticatedService {
    

    async getShareholderDetail(shareholderId: number): Promise<Response<Shareholder>> {
        const url = `${getEnvConfig().apiURL}/auth/me`

        return getResponse(url, this.token, 'GET', undefined, undefined)
    }

    async getShareholderResume(shareholderId: number): Promise<Response<ShareholderResume>> {
    
        const url = `${getEnvConfig().apiURL}/auth/shareholder/${shareholderId}/movements`
        console.log(url)

        return getResponse(url, this.token, 'GET', undefined, undefined)
    }

}