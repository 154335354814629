import { Shareholder } from "@/services/shareholder/types";
import { defineStore } from "pinia";
import { ShareholderService } from "@/services/shareholder/ShareholderService";

export const shareholderStore = defineStore("shareholder", {
  state: () => {
    const shareholder: Shareholder = new Shareholder()
    const shareholderService = new ShareholderService();
    const promise: Promise<Response> = new Promise((res) => res);

    return {
      shareholder,
      shareholderService,
      promise,
    };
  },

  actions: {
    async query(id: number) {
      await this.shareholderService
        .getShareholderDetail(id)
        .then(({ data }) => (this.shareholder = data));
      
    },
  },

  getters: {
    getData(state) {
      return state.shareholder;
    },
  },
});

export type ShareholderStore = ReturnType<typeof shareholderStore>;
