import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full" }
const _hoisted_2 = { class: "text-xl text-primary-green font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_BarChart, {
      chartData: _ctx.testData,
      options: _ctx.labeled ? _ctx.optionsLabeled : _ctx.options
    }, null, 8, ["chartData", "options"])
  ]))
}