import { getEnvConfig } from "@/configs/UrlConfig";
import { AuthenticatedService } from "../auth/AuthenticatedService";
import { getResponse, keysToSnake } from "../utils";
import { Response } from "../types";
import { NotificationSettings, NotificationSettingsEdit, Notification } from "./types";

export class NotificationServices extends AuthenticatedService{

    async getSettings(user_id:number): Promise<Response<NotificationSettings>>{
        const url = `${getEnvConfig().apiURL}/notifications/settings/get?user_id=${user_id}`

        return getResponse(url, this.token)
    }

    async editSettings(user_id:number,settings: NotificationSettingsEdit): Promise<Response<null>>{
        const url = `${getEnvConfig().apiURL}/notifications/settings/edit?user_id=${user_id}`

        return getResponse(url, this.token, "POST", keysToSnake(settings))
    }

    async getNotifications(user_id:number): Promise<Response<Notification[]>>{
        const url = `${getEnvConfig().apiURL}/notifications/all?user_id=${user_id}`

        return getResponse(url, this.token)
    }

    async readNotification(notificationId: number): Promise<Response<Notification>>{
        const url = `${getEnvConfig().apiURL}/notifications/read`

        return getResponse(url, this.token, 'POST', {'notification_id': notificationId})
    }

    async readAllNotifications(user_id:number): Promise<Response<null>>{
        const url = `${getEnvConfig().apiURL}/notifications/read-all?user_id=${user_id}`

        return getResponse(url, this.token, 'POST', {'user_id': user_id})
    }
}