
import { PropType, computed, defineComponent, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { mdiBorderRadius } from "@mdi/js";
import { MonthlyInfo } from "@/services/charts/types";
import { translateMonth } from "@/services/utils";
import { toBadgeFormat } from "@/services/utils";

Chart.register(...registerables, ChartDataLabels);
export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    labeled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#9BB441",
    },
    actionsData: {
      type: Array as PropType<MonthlyInfo[]>,
      required: true,
    },
    showYear: {
      type: Boolean,
      default: false,
    },
    showYearAndMonth: {
      type: Boolean,
      default: false,
    },
    // TODO   se tiene que poder cambiar
  },
  components: { BarChart },
  setup(prop) {
    const chartData = computed(() => {
      let actionsTotalData = prop.actionsData.map((action) => {
        return action.totalClp;
      });
      return actionsTotalData;
    });
    const labelsData = computed(() => {
      let actionsTotalData = prop.actionsData.map((action) => {
        if (prop.showYear) {
          return action.year;
        } else {
          return prop.showYearAndMonth
            ? `${translateMonth(action.month)} ${action.year}`
            : translateMonth(action.month);
        }
      });
      return actionsTotalData;
    });
    const data = ref([30, 40, 60, 70, 5]);

    const optionsLabeled = ref<ChartOptions<"bar">>({
      scales: {
        xAxes: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        yAxes: {
          grid: {
            drawBorder: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          // Usar una función de callback personalizada para formatear el tooltip
          callbacks: {
            label: (tooltipItem: any) => {
              // Aquí puedes personalizar el formato del tooltip según tus necesidades
              const value = tooltipItem.formattedValue; // Valor original
              const formattedValue = toBadgeFormat(value); // Formatear con puntos en lugar de comas
              return formattedValue;
            },
          },
        },
      },
    });
    const options = ref<ChartOptions<"bar">>({
      scales: {
        xAxes: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        yAxes: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            callback: (value, index, values) => {
              // Formatear los números en la escala del eje y con puntos en lugar de comas
              return Number(value).toLocaleString("es-ES");
            },
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Valor en CLP",
          align: "start",
        },
        datalabels: {
          display: false,
          formatter: (value, context) => {
            // Formatea el número con puntos en lugar de comas

            const formattedValue = Number(value).toLocaleString("es-ES");
            return formattedValue;
          },
        },
        tooltip: {
          // Usar una función de callback personalizada para formatear el tooltip
          callbacks: {
            label: (tooltipItem: any) => {
              // Aquí puedes personalizar el formato del tooltip según tus necesidades
              const value = tooltipItem.parsed.y; // Valor original
              const formattedValue = toBadgeFormat(value.toString()); // Formatear con puntos en lugar de comas
              return formattedValue;
            },
          },
        },
      },
    });
    const testData = computed<ChartData<"bar">>(() => ({
      labels: labelsData.value,

      datasets: [
        {
          barPercentage: prop.labeled ? 0.4 : 0.2,
          borderRadius: 15,
          data: chartData.value,
          backgroundColor: [prop.color],
        },
      ],
    }));
    return { testData, optionsLabeled, options, chartData };
  },
});
