
import { PropType, computed, defineComponent, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { MonthlyInfoBalance } from "@/services/charts/types";
import { reformatDateDayMonth } from "@/services/utils";
import { Title } from "chart.js";
import { translateMonth } from "@/services/utils";

Chart.register(...registerables, ChartDataLabels);

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    labeled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#008b4e",
    },
    actionsData: {
      type: Array as PropType<MonthlyInfoBalance[]>,
      required: true,
    },
    showYear: {
      type: Boolean,
      default: false,
    },
  },

  components: { BarChart },
  setup(prop) {
    const chartData = computed(() => {
      // console.log("chartData", prop.actionsData[0].balanceTotal);
      let actionsTotalData = prop.actionsData.map((action) => {
        if (action.sharesGroupB) {
          return action.sharesGroupB;
        }
        return 0;
      });

      return actionsTotalData;
    });

    const labelsData = computed(() => {
      let actionsTotalData = prop.actionsData.map((action) => {
        if (prop.showYear) {
          return action.year;
        } else {
          return translateMonth(action.month);
        }
      });
      return actionsTotalData;
    });

    const chartLabel = computed(() => {
      let actionsTotalData = prop.actionsData.map((action) => {
        return action.totalShares;
      });
      return actionsTotalData;
    });

    const optionsLabeled = ref<ChartOptions<"bar">>({
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          grid: {
            drawBorder: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Valor en CLP",
          align: "start",
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          // Usar una función de callback personalizada para formatear el tooltip
          callbacks: {
            label: (tooltipItem: any) => {
              // Aquí puedes personalizar el formato del tooltip según tus necesidades
              const formattedValue =
                prop.actionsData[tooltipItem.dataIndex].ufValue; // Formatear con puntos en lugar de comas
              return formattedValue;
            },
          },
        },
      },
    });
    const options = ref<ChartOptions<"bar">>({
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          grid: {
            drawBorder: false,
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
        },
        tooltip: {
          // Usar una función de callback personalizada para formatear el tooltip
          callbacks: {
            label: (tooltipItem: any) => {
              // Aquí puedes personalizar el formato del tooltip según tus necesidades
              const value = tooltipItem.formattedValue; // Valor original
              const formattedValue = Number(value).toLocaleString("es-ES"); // Formatear con puntos en lugar de comas
              return formattedValue;
            },
          },
        },
      },
    });
    const testData = computed<ChartData<"bar", number[], unknown>>(() => ({
      labels: labelsData.value,
      datasets: [
        {
          barPercentage: prop.labeled ? 0.6 : 0.2,
          borderRadius: 12,
          data: chartData.value,
          backgroundColor: [prop.color],
        },
      ],
    }));

    return { testData, optionsLabeled, options, chartData };
  },
});
