import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-5 h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartSection = _resolveComponent("ChartSection")!
  const _component_ActionsChartSection = _resolveComponent("ActionsChartSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_ChartSection, { class: "lg:col-span-1 col-span-2" }),
      _createVNode(_component_ActionsChartSection, { class: "lg:col-span-1 col-span-2" })
    ])
  ]))
}